#btnNacional:hover{
  cursor: pointer;
}

.onclick:hover{
  cursor:pointer;
  box-shadow: 2px 0px 2px 2px #333;
}

.capa{

  width: 100%;
  height: 60vh;

  display: inline-block;

}

.capax{
  width: auto;
  height: auto;

}

.capagaugen{

  height: 150px;
  width: auto;

  position: absolute;
  bottom: 25%;
  left: 20px;

}

.gaugen{
  width: 100%;
  height: 150px;

}

.bordemapa{
  width:  100%;
  height:  60vh;

  display: flex;
  justify-content: center;
  align-items: center;

}

.mapa{
 /* width: 70%;
  height: 80%;

border:1px solid green;*/
margin-top: 5%;
}



.fondo{
  background: 'rgba(250, 250, 250, 0.8)';/*'#E3DCCD';*/
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
}

/*
@media (min-width:1650px){
.capagaugen{

  height: 150px;
  bottom: 25%;

}

.gaugen{
  height: 150px;

}
}*/

@media (max-width:1650px){
.capagaugen{

  height: 200px;
  bottom: 17%;

}

.gaugen{
  height: 200px;

}
}

@media (max-width:1550px){
.bordemapa{
  height:  60vh;

}

.mapa{
  width: 90%;
  height: 92%;


}

.capagaugen{

  height: 120px;
  bottom: 30%;

}

.gaugen{
  height: 120px;

}
}


@media (max-width:1450px){
.bordemapa{
  height:  60vh;

}

.mapa{
  width: 90%;
  height: 80%;


}

.capagaugen{

  height: 150px;
  bottom: 15%;

}

.gaugen{
  height: 150px;

}
}


@media (max-width:1350px){
.bordemapa{
  height:  65vh;

}

.mapa{
  width: 90%;
  height: 80%;


}

.capagaugen{

  height: 150px;
  bottom: 15%;

}

.gaugen{
  height: 150px;

}
}


@media (max-width:1200px){
.bordemapa{
  height:  65vh;

}

.mapa{
  width: 100%;
  height: 80%;

}
}

@media (max-width:992px){
.bordemapa{
  height:  65vh;

}

.mapa{
  width: 40%;
  height: 80%;

}

.capagaugen{

  height: 150px;
  width: auto;

  left: 20%;
  bottom: 10%;
}

.gaugen{
  width: 100%;
  height: 150px;

}
}

.PDFView{
  width: 100%;
  height: 90vh;

  margin-top: 10px;
}

/*

@media (max-width:668px){
  .mapa{
    width: 80%;
    height: 800px;
}
}

@media (max-width:568px){
  .mapa{
    width: 90%;
    height: 800px;
}
}

*/