.border{
  border:10px solid #333;
}

.mapa_alto{
  height: 280px;
  margin-top: 10px;
}
.border:hover{
  cursor: pointer;
  stroke: '#FFF';
}

