.border_rojo{
    border: 2px solid red;

}

.border_azul{
    border: 2px solid blue;

}

.border_verde{
    border: 2px solid green;

}

.tarjeta_altura{
    height: 90% ;


}

.hiddenScrollY{
    scrollbar-width: none;
    overflow-y: hidden;
}


.t-ancho{
    width: 430px;
}

.t-alto{
    height: 40vh;   /*35vh /*350px;*/
}

.t-alto_2{
    height: 350px;
}

.t-fontSize{
    font-size: small;
}


@media(max-width:996px) {
   /* .t-ancho{
        width: 96%;
    }

    .t-alto {
        height: 500px;

    }

    .t-alto_2{
        height: 500px;
    }*/

    .t-fontSize{
        font-size: small;
    }
}
/*
@media(max-width:668) {
    .t-ancho {
        width: 96%;

    }

    .t-alto {
        height: 700px
    }

    .t-alto_2 {
        height: 500px;
    }

    t.t-fontSize {
        font-size: small;
    }
}
*/

.ulli{
    margin:0px;
    padding: 1px;
}

.card-alto{
    height: 360px;
}

.sobreimg{
    z-index: 1000;
    position: absolute;
    left:3%;
    top: 35%;
}

.formula{
    position:absolute;
    z-index: 1001;
    right:3%;
    top:5%;
    width:65%
}

.invisible{
    display: none;
}

.sobreimg_1{
    position: absolute;
    bottom: 1%;
    left: 10%;

}

.sobreimg_2 {
    position: absolute;
    top: 3%;
    left: 10%;

}

.border{
    stroke: '#767474';
    stroke-width:1px;
}

.border:hover{
    z-index: 1000;
    stroke: #545454;
    stroke-width:2px;
}

.hober:hover{
    cursor: pointer;

}