.TableHeaderCell{
  background:  rgba(53,53,53,.8) ;
  color: #fff;
  height: 30px;
  text-align: center;

}

.TableCell{
  text-align: right;
}

.TableRow{
  text-align: right;
}