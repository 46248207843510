.newmodal{
  padding: 0;
  margin: 0;

  z-index: 10000;
  position: fixed;

  background: rgba(53,53,53,.3);

  top:0%;

  width: 100%;
  min-height: 100vh;

  border: 1px solid red;
}