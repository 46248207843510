
.fondo{

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.margen{
    margin-top: 10%;
    margin-left: 10%;
}

.logo{
    width: 100%;
    padding: 5px;
}

.texto{
    color:cornflowerblue;
    font-family: 'Times New Roman', Times, serif;
    font-size: medium;
}

.hiddenScrollY{
    overflow-y: hidden;
}

.hiddenScrollX{
    overflow-x: hidden;
}